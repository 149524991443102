<template lang="pug">
  .products-swatches-with-ugc
    .row.spinner-wrap(v-if="loadingProducts || !isMounted")
      MrSpinner

    .row.swatches-outer-wrap(v-if="isMounted")
      .swatches-wrap
        SwatchSelector(v-if="!loadingProducts" :swatches="productSwatches" :show-arrows="false" :center-on-desktop="true" @swatch-selected="onSwatchSelected")

    .row
      .product-description.xs-f-xsmall.text-center
        h3.max-at-tweak {{selectedProductType}}

    .row(v-if="selectedProductConfig")
      .ugc-wrapper.xs-12
        .carousel-wrap
          SimpleCarouselSSR.the-carousel(:options="carouselOptions" tabindex="0" role="region" aria-roledescription="carousel of images")
            .card-container(v-for="(ugcEntry, index) in selectedProductConfig.ugcEntries" :key="selectedProduct.code + index" tabindex="0")
              .img-wrap
                .a-box-xs-1x1
                  .a-box-content
                    img-box(:media-obj="ugcEntry.image" loading-type="lazy")
              .description-wrap
                .description-inner-wrap.text-color-1
                  .name-wrap.xs-f-large.sm-f-medium.md-f-large.max-at-tweak.color-mr-purple(v-if="ugcEntry.name") {{ ugcEntry.name }}
                  .name-wrap.xs-f-large.sm-f-medium.md-f-large.max-at-tweak.color-mr-purple(v-else) &nbsp;
                  .location-wrap.xs-f-small.sm-f-xxsmall.md-f-small.max-at-tweak.bold(v-if="ugcEntry.location") {{ ugcEntry.location }}
                  .location-wrap.xs-f-small.sm-f-xxsmall.md-f-small.max-at-tweak.bold(v-else) &nbsp;
                  .applied-services-wrap.xs-f-small.sm-f-xxsmall.md-f-small.max-at-tweak(v-if="ugcEntry.appliedServices") {{ugcEntry.appliedServices}}

    .row(v-if="cta")
      .cta-wrapper
        a.mr-btn.btn-fixed-width.xs-f-xsmall.max-at-tweak(:href="cta.url") {{cta.text}}
</template>

<script>

  import MrSpinner from "@components/MrSpinner";
  import SimpleCarouselSSR from '@components/SimpleCarouselSSR';
  import { getProductsById } from "@services/vueProductSvc";

  export default {
    name: "ProductsSwatchesWithUgc",

    components: {
      MrSpinner,
      SimpleCarouselSSR,
      SwatchSelector: () => import('@components/PDP/SwatchSelector'),
    },

    inject: ['getObjProperty'],

    props: {
      productsConfig: {
        type: Array,
        default() {
          return [];
        },
      },

      title: {
        type: String,
        default: 'See Why Over 2.5 Million Women Have Tried and Loved Madison Reed',
      },

      swatchSelectedPrefix: {
        type: String,
        default: '',
      },

      cta: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        productsMap: {},
        selectedSwatch: null,
        loadingProducts: false,
        isMounted: false,
      };
    },

    computed: {
      productSwatches() {
        if (!this.productsConfig || !this.productsConfig.length) {
          return [];
        }

        let swatches = this.productsConfig.map((productConfig, index) => {
          let product = this.productsMap[this.getObjProperty(productConfig, 'product.id')] || {};

          return {
            disabled: false,
            selected: (!index && !this.selectedSwatch) || this.getObjProperty(this.selectedSwatch, 'product.id') === product.id,
            bestMatch: false,
            subscribed: false,
            imageUrl: this.getObjProperty(product, 'imagery.toneSwatch.url'),
            imageAltText: this.getObjProperty(product, 'imagery.toneSwatch.alt_text'),
            title: '',
            product,
            productConfig,
          };
        });

        swatches = swatches.sort(a => {
          return a.bestMatch ? -1 : 0;
        });

        return swatches;
      },

      selectedProductConfig() {
        return this.selectedSwatch && this.selectedSwatch.productConfig;
      },

      selectedProduct() {
        return this.selectedSwatch && this.selectedSwatch.product;
      },

      selectedProductName() {
        //- color_kit is a special flake
        return this.selectedProduct && (this.selectedProduct.product_type == 'color_kit' ? this.selectedProduct.short_description : this.selectedProduct.name);
      },

      selectedProductType() {
        return this.selectedProduct && (this.selectedProduct.product_type == 'color_kit' ? this.selectedProduct.name : this.selectedProduct.product_type_display_name);
      },

      carouselOptions() {
        return {
          arrows: false,
          dots: false,
          autoplay: false,
          infinite: true,
          slidesToShow: 3,
          lazyLoad: 'progressive',
          responsive: [
            {
              breakpoint: 426,
              settings: {
                initialSlide: 1,
                slidesToShow: 1.5,
                lazyLoad: 'ondemand',
                centerMode: false,
              }
            },
            {
              breakpoint: 559,
              settings: {
                initialSlide: 1,
                slidesToShow: 2.5,
                lazyLoad: 'ondemand',
                centerMode: false,
              },
            },
          ],
        };
      },
    },

    watch: {
      productsConfig: 'onProductsConfigChange',
    },

    mounted() {
      this.onProductsConfigChange();
      this.isMounted = true;
    },

    methods: {
      onSwatchSelected(swatch) {
        if (!swatch) {
          return;
        }

        this.selectedSwatch = swatch;

        this.trackMREvent(`${this.swatchSelectedPrefix} - Clicked UGC Shade`, { code: swatch.product.code});
      },

      getLocationText(pixleeMedia) {
        return ['colorbar', 'color bar'].some(tag => (pixleeMedia.categories || []).includes(tag)) ? "Hair Color Bar" : "At-Home Kit";
      },

      onProductsConfigChange() {
        let ids = this.productsConfig.map(productConfig => this.getObjProperty(productConfig, 'product.id')).filter(Boolean);

        if (!ids.length) {
          this.productsMap = {};
          return;
        }


        this.loadingProducts = true,

        getProductsById({ ids }).then(res => {
          let products = res.data;
          (products || [])
            .filter(product => product.status && product.visible)
            .forEach(product => this.productsMap[product.id] = product);
          this.loadingProducts = false;
        }).catch(() => this.loadingProducts = false);
      },
    },
  };
</script>

<style lang="stylus">
.products-swatches-with-ugc
  overflow hidden
  padding-bottom 3em

  @media mq-mobile
    padding-left 1em

  .spinner-wrap
    margin-top 2em

  .swatches-outer-wrap
    .swatches-wrap
      @media mq-mobile
        margin-left 0%
        transform unset
        display unset
        margin-left -0.5em

        .swatch-selector
          padding-left 0%
          padding-right 0%

  .product-description
    margin 1.15em 0em

    @media mq-mobile
      text-align left

  .ugc-wrapper
    .carousel-wrap
      left 50%
      transform translateX(-50%)
      position relative
      display block
      max-width 840px

      @media mq-mobile
        transform unset
        left 0%
        max-width unset

  .card-container
    @media mq-mobile
      margin-right 1.15em

    .description-wrap
      padding 1em 0.3em

      .description-inner-wrap
        display flex
        align-items center
        min-height 115px
        flex-direction column
        text-align center

        @media mq-mobile
          align-items flex-start
          text-align left

      .name-wrap
        margin-bottom 0.25em

  .cta-wrapper
    padding 2em 1em
    padding-bottom 0.5em

    @media mq-mobile
      padding-left 0em

    .mr-btn
      margin-left 50%
      transform translateX(-50%)
</style>
